import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import BreedersSection from 'dpl/homepage/components/BreedersSection';
import withRotatingContent from 'dpl/decorators/withRotatingContent';

function BreedersSectionContainer(props) {
  return <BreedersSection {...props} />;
}

export default compose(
  connect(({ server: { featuredBreeders } }) => ({
    featuredBreeders,
    rotatingContentCount: featuredBreeders.length,
    rotatingContentStartIdx: 0
  })),
  withRotatingContent
)(BreedersSectionContainer);
