import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  ORDERED_SEARCH_TYPES,
  ORDERED_SEARCH_TYPE_VALUES
} from 'dpl/shared/constants/shared';
import Icon from 'dpl/common/components/Icon';
import TransitionMenu from 'dpl/common/components/TransitionMenu';

export default class MobileSearch extends Component {
  static propTypes = {
    openSearchBreedersMenu: PropTypes.func.isRequired,
    openSearchSheltersMenu: PropTypes.func.isRequired
  };

  state = {
    selectedSearchType: ORDERED_SEARCH_TYPES.BREEDERS
  };

  handleMenuItemClick = (e, idx) => {
    this.setState({ selectedSearchType: ORDERED_SEARCH_TYPE_VALUES[idx] });
  };

  handleSearchClick = () => {
    const { openSearchBreedersMenu, openSearchSheltersMenu } = this.props;
    const { selectedSearchType } = this.state;

    return selectedSearchType === ORDERED_SEARCH_TYPES.BREEDERS
      ? openSearchBreedersMenu()
      : openSearchSheltersMenu();
  };

  render() {
    const { selectedSearchType } = this.state;

    return (
      <div className="MobileHomeSearch ph4">
        <div className="MobileHomeSearch__container shadow-3 bg-white br1">
          <div className="f4-xl pt5-sm pt4 ph4 ph8-xl z-1 bb b--light-gray">
            <TransitionMenu
              items={ORDERED_SEARCH_TYPE_VALUES}
              selectedIdx={ORDERED_SEARCH_TYPE_VALUES.indexOf(
                selectedSearchType
              )}
              onItemClick={this.handleMenuItemClick}
              itemClassName="pb5-sm pb4 stone-700"
            />
          </div>
          <div className="relative">
            <input
              className="b--none br1 br--bottom f3 pv4 pl4 pr10 w-100 ba"
              placeholder={
                selectedSearchType === ORDERED_SEARCH_TYPES.BREEDERS
                  ? 'Enter a breed, e.g. “Pomeranian”'
                  : 'Enter your zip code or city'
              }
              onClick={this.handleSearchClick}
              onMouseDown={e => e.preventDefault()}
            />
            <Icon
              className="MobileHomeSearch__icon stone-700 absolute right-0 mh4 mh8-xl transform-center pointer-events-none"
              name="magnifier"
            />
          </div>
        </div>
      </div>
    );
  }
}
