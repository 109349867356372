import React from 'react';
import { connect } from 'react-redux';

import SheltersSection from 'dpl/homepage/components/SheltersSection';

function SheltersSectionContainer(props) {
  return <SheltersSection {...props} />;
}

export default connect(
  ({
    server: {
      shelterCities: { data }
    }
  }) => ({
    shelterCities: data
  })
)(SheltersSectionContainer);
