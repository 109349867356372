import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withRotatingContent from 'dpl/decorators/withRotatingContent';
import QuoteCarousel from 'dpl/components/QuoteCarousel';

function PressSectionContainer(props) {
  return <QuoteCarousel {...props} />;
}

export default compose(
  connect(({ server }) => ({
    quotes: server.pressBlurbs,
    rotatingContentCount: server.pressBlurbs.length
  })),
  withRotatingContent
)(PressSectionContainer);
