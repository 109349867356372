import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import { throttle, isElementInViewport } from 'dpl/shared/utils';

const ROTATION_INTERVAL_MS = 6000;

export default function withRotatingContent(WrappedComponent) {
  return class RotatingContent extends Component {
    static propTypes = {
      rotatingContentCount: PropTypes.number.isRequired,
      rotatingContentStartIdx: PropTypes.number
    };

    static defaultProps = {
      rotatingContentStartIdx: null
    };

    state = {
      shownIdx: this.props.rotatingContentStartIdx,
      isContentShown: false,
      isPaused: false
    };

    ref = createRef();

    intervalId = null;

    componentDidMount() {
      const didStart = this.initIfNeeded();
      if (!didStart) {
        this.bindScrollHandler();
      }
    }

    componentWillUnmount() {
      this.unbindScrollHandler();
    }

    initIfNeeded() {
      if (this.ref.current && isElementInViewport(this.ref.current)) {
        this.setState({ shownIdx: 0 });
        this.resetRotationInterval();
        return true;
      }

      return false;
    }

    bindScrollHandler() {
      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener('touchmove', this.handleScroll);
    }

    unbindScrollHandler() {
      window.removeEventListener('scroll', this.handleScroll);
      window.removeEventListener('touchmove', this.handleScroll);
    }

    handleScroll = throttle(() => {
      const didStart = this.initIfNeeded();
      if (didStart) {
        this.unbindScrollHandler();
      }
    }, 100);

    resetRotationInterval() {
      window.clearInterval(this.intervalId);
      this.intervalId = window.setInterval(
        this.increment,
        ROTATION_INTERVAL_MS
      );
    }

    updateShownIdxIfNeeded(idx) {
      if (idx !== this.state.shownIdx) {
        this.setState({
          shownIdx: idx,
          isContentShown: false
        });
      }
    }

    pause = idx => {
      if (typeof idx !== 'number') {
        idx = this.state.shownIdx;
      }
      window.clearInterval(this.intervalId);
      this.updateShownIdxIfNeeded(idx);
      this.setState({
        isPaused: true
      });
    };

    resume = () => {
      this.resetRotationInterval();
      this.setState({
        isPaused: false
      });
    };

    increment = () => {
      this.updateShownIdxIfNeeded(
        (this.state.shownIdx + 1) % this.props.rotatingContentCount
      );
    };

    next = () => {
      if (!this.state.isPaused) {
        this.resetRotationInterval();
      }
      this.increment();
    };

    prev = () => {
      if (!this.state.isPaused) {
        this.resetRotationInterval();
      }
      this.updateShownIdxIfNeeded(Math.max(this.state.shownIdx - 1, 0));
    };

    componentDidUpdate(prevProps, prevState) {
      const { shownIdx, isContentShown } = this.state;
      if (!isContentShown && prevState.shownIdx !== shownIdx) {
        // HACK: ensure we allow time for repaint when `false` so fade-in
        // animation displays correctly when setting to `true`
        window.setTimeout(() => {
          this.setState({ isContentShown: true });
        }, 100);
      }
    }

    render() {
      const { rotatingContentCount } = this.props;

      const { isContentShown, shownIdx, isPaused } = this.state;

      return (
        <WrappedComponent
          setRef={this.ref}
          rotatingContentInterval={ROTATION_INTERVAL_MS}
          isRotatingContentShown={isContentShown}
          shownRotatingContentIdx={shownIdx}
          isRotatingContentPaused={isPaused}
          pauseRotatingContent={this.pause}
          resumeRotatingContent={this.resume}
          nextRotatingContent={this.next}
          prevRotatingContent={this.prev}
          isOnFirstRotatingContentStep={shownIdx === 0}
          isOnLastRotatingContentStep={shownIdx === rotatingContentCount - 1}
          {...this.props}
        />
      );
    }
  };
}
