import React, { Component } from 'react';

import {
  ORDERED_SEARCH_TYPES,
  ORDERED_SEARCH_TYPE_VALUES
} from 'dpl/shared/constants/shared';
import { scrollTo } from 'dpl/shared/utils';
import BreedSearch from 'dpl/components/BreedSearch';
import Icon from 'dpl/common/components/Icon';
import TransitionMenu from 'dpl/common/components/TransitionMenu';
import GenericSearch from 'dpl/components/Search';
import LocationResultsListContainer from 'dpl/search/containers/LocationResultsListContainer';
import { navigateToShelterResults } from 'dpl/shelter_search_results/utils';

const SCROLL_TOP = 400;

export default class Search extends Component {
  state = {
    selectedSearchType: ORDERED_SEARCH_TYPES.BREEDERS,
    isOpen: window.__DPL_BOT_UA
  };

  handleMenuItemClick = (e, idx) => {
    // dont want to close the menu
    e.preventDefault();

    this.setState({ selectedSearchType: ORDERED_SEARCH_TYPE_VALUES[idx] });
    this.handleDropdownChange(true);
  };

  handleDropdownChange = isOpen => {
    if (isOpen && window.scrollY < SCROLL_TOP) {
      scrollTo({
        top: SCROLL_TOP,
        behavior: 'smooth'
      });
    }

    this.setState({ isOpen });
  };

  render() {
    const { selectedSearchType, isOpen } = this.state;

    return (
      <div className="HomeSearch shadow-3 bg-white br1 relative z-999 transition">
        <div className="f4-xl pt5-sm pt4 ph4 ph8-xl z-1 bb b--light-gray">
          <TransitionMenu
            items={ORDERED_SEARCH_TYPE_VALUES}
            selectedIdx={ORDERED_SEARCH_TYPE_VALUES.indexOf(selectedSearchType)}
            onItemClick={this.handleMenuItemClick}
            itemClassName="pb5-sm pb4 fw-medium stone-700"
          />
        </div>
        <div className="relative">
          {ORDERED_SEARCH_TYPES.BREEDERS === selectedSearchType && (
            <BreedSearch
              className="f4-xl pv5-sm pv4 pl4 pl8-xl pr14-sm pr10 w-100 bw1 transition b--none br1 br--bottom"
              placeholder="Enter a breed, e.g. “Pomeranian”"
              onChange={this.handleDropdownChange}
              isOpen={isOpen}
            />
          )}
          {ORDERED_SEARCH_TYPES.SHELTERS === selectedSearchType && (
            <GenericSearch
              ResultsComponent={LocationResultsListContainer}
              inputClassName="f4-xl pv5-sm pv4 pl4 pl8-xl pr14-sm pr10 w-100 bw1 transition b--none br1 br--bottom"
              placeholder="Enter your zip code or city"
              onChange={this.handleDropdownChange}
              isOpen={isOpen}
              onSelectItem={navigateToShelterResults}
            />
          )}
          <Icon
            className="stone-700 absolute right-0 mh4 mh8-xl transform-center pointer-events-none"
            name="magnifier"
          />
        </div>
      </div>
    );
  }
}
