import React from 'react';
import { useSelector } from 'react-redux';

import { BUYER_APP_STORE_URL } from 'dpl/shared/constants/urls';
import { sendAnalyticsEvent } from 'dpl/util/analytics';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_VIEWS,
  ANALYTICS_VIEW_FEATURES,
  ANALYTICS_VIEW_FEATURE_LOCATIONS
} from 'dpl/constants/analytics';
import { GDLinkV1 } from '@core';

export function DownloadHero() {
  const {
    downloadHeroAppPreviewUrl,
    downloadHeroAppleStoreUrl,
    downloadHeroAndroidStoreUrl
  } = useSelector(({ server }) => server);

  function onBadgesClick() {
    sendAnalyticsEvent(ANALYTICS_EVENTS.BUTTON_CLICKED, {
      view: ANALYTICS_VIEWS.HOME_PAGE,
      view_feature: ANALYTICS_VIEW_FEATURES.CONSUMER_APP_STORE_LINK,
      view_feature_location: ANALYTICS_VIEW_FEATURE_LOCATIONS.PUSHDOWN,
      button_cta: 'N/A'
    });
  }

  return (
    <div className="DownloadHero flex flex-column items-center">
      <div className="DownloadHero__topPanel">
        <img
          src={downloadHeroAppPreviewUrl}
          className="DownloadHero__appImage"
          alt="gooddog mobile application"
        />
      </div>
      <div className="DownloadHero__bottomPanel ph2 pt6 pb12 tc">
        <h1 className="f6 pt6 lh-1">Find the dog of your dreams</h1>
        <p className="mt3">
          Download the Good Dog app to find trustworthy breeders near you.
        </p>
        <GDLinkV1
          className="mt3 flex justify-center items-center"
          href={BUYER_APP_STORE_URL}
          onClick={onBadgesClick}
        >
          <img
            className="ph1 DownloadHero__appStoreBadge"
            src={downloadHeroAppleStoreUrl}
            alt="apple app store badge"
          />
          <img
            className="ph1 DownloadHero__appStoreBadge"
            src={downloadHeroAndroidStoreUrl}
            alt="androind app store badge"
          />
        </GDLinkV1>
      </div>
    </div>
  );
}
