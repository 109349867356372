import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { HealthTestPropTypes } from 'dpl/shared/utils/customPropTypes';
import { groupBy } from 'dpl/shared/utils';
import SmartImage from 'dpl/components/SmartImage';
import TooltipWrapper from 'dpl/common/tooltip/components/TooltipWrapper';

const DISPLAYED_HEALTH_TEST_CATEGORY_COUNT = 3;

function BreederProfileHealthTestCategoriesItem({ healthTests }) {
  const { health_test_category: category } = healthTests[0];

  const tooltipContent = (
    <div className="tc lh-copy f2">
      <p className="underline">This Breeder&apos;s Tests</p>
      {healthTests.length > 0 && (
        <p className="mt2 fw-medium">
          {healthTests.map(({ name }) => name).join(', ')}
        </p>
      )}
      {Boolean(category.description) && (
        <p className="mt4">{category.description}</p>
      )}
    </div>
  );

  const img = (
    <div className="flex flex-column items-center h-100">
      <div className="relative">
        <div className="tc f0 mb2">
          <SmartImage
            className="br-100"
            height="64px"
            width="64px"
            src={category.photo_url}
          />
        </div>
      </div>
      <p className="f3 tc truncate w-100">{category.name}</p>
    </div>
  );

  return (
    <div className="BreederProfileHealthTestCategoriesItem">
      <TooltipWrapper
        title={tooltipContent}
        hideDelay={0}
        minWidth="220px"
        className="w-100"
      >
        <div className="dib pv4 fw-medium w-100">{img}</div>
      </TooltipWrapper>
    </div>
  );
}

BreederProfileHealthTestCategoriesItem.propTypes = {
  healthTests: PropTypes.arrayOf(PropTypes.shape(HealthTestPropTypes))
    .isRequired
};

export default class BreederProfileHealthTestCategories extends Component {
  static getDerivedStateFromProps({ healthTests }) {
    const healthTestsByCategoryId = Object.values(
      groupBy(healthTests, 'health_test_category.id')
    );

    const displayedCategories = healthTestsByCategoryId.slice(
      0,
      DISPLAYED_HEALTH_TEST_CATEGORY_COUNT
    );

    const remainingCategories = healthTestsByCategoryId.slice(
      DISPLAYED_HEALTH_TEST_CATEGORY_COUNT
    );

    if (remainingCategories.length === 1) {
      displayedCategories.push(remainingCategories.pop());
    }

    return {
      displayedCategories,
      remainingCategories
    };
  }

  state = {
    isMoreShown: false,
    displayedCategories: [],
    remainingCategories: []
  };

  handleMoreClick = () => {
    this.setState({
      isMoreShown: !this.state.isMoreShown
    });
  };

  render() {
    const { displayedCategories, remainingCategories, isMoreShown } =
      this.state;

    return (
      <div className="BreederProfileHealthTestCategories">
        {displayedCategories.length > 0 && (
          <div>
            <ul className="row no-gutters flex-wrap items-center">
              {displayedCategories.map((tests, idx) => (
                <li key={idx} className="col-3 mb2">
                  <BreederProfileHealthTestCategoriesItem healthTests={tests} />
                </li>
              ))}
              {remainingCategories.length > 0 &&
                (isMoreShown ? (
                  remainingCategories.map((tests, idx) => (
                    <li key={idx} className="col-3 mb2">
                      <BreederProfileHealthTestCategoriesItem
                        healthTests={tests}
                      />
                    </li>
                  ))
                ) : (
                  <li className="col-3 mb1 tc">
                    <button
                      type="button"
                      className="fw-medium primary pv3 ph3 ba br-100"
                      onClick={this.handleMoreClick}
                    >
                      +{remainingCategories.length}
                    </button>
                    <p>&nbsp;</p>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}
