import React from 'react';
import PropTypes from 'prop-types';
import { stringify } from 'qs';

import HorizontalSlider from 'dpl/components/HorizontalSlider';
import SmartImage from 'dpl/components/SmartImage';

export default function SheltersSection({ shelterCities }) {
  return (
    <div className="HomeSheltersSection">
      <div className="container">
        <HorizontalSlider
          persistentArrows
          containerClassName="row flex-nowrap pv20 nv20"
          iconClassName="f3 bg-white o-80 br-100 box-shadow-strong b black pv2 ph2"
        >
          {shelterCities.map(city => (
            <div key={city.name} className="col">
              <a
                className="HomeSheltersSection__city db relative"
                href={`/shelters#locate=${stringify({
                  query: { location: city.name }
                })}`}
              >
                <div className="HomeSheltersSection__cityContent transition f0">
                  <SmartImage
                    lazy
                    Tag="div"
                    alt={city.name}
                    className="cover bg-center br1"
                    width="327px"
                    height="436px"
                    loadingClass="bg-light-gray"
                    src={city.pictureUrl}
                  />
                  <div className="w-100 h-100 absolute top-0 left-0 scrim--gradient-black br1">
                    <p className="pb8 absolute bottom-0 left-0 right-0 text-shadow white title fw-medium tc">
                      {city.name}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </HorizontalSlider>
      </div>
    </div>
  );
}

SheltersSection.propTypes = {
  shelterCities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      pictureUrl: PropTypes.string.isRequired
    })
  ).isRequired
};
