import React from 'react';

import initReactApp from 'dpl/util/initReactApp';
import HomeSearchContainer from 'dpl/homepage/containers/SearchContainer';
import HomeScreeningSectionContainer from 'dpl/homepage/containers/ScreeningSectionContainer';
import HomeBreedersSectionContainer from 'dpl/homepage/containers/BreedersSectionContainer';
import HomeSheltersSectionContainer from 'dpl/homepage/containers/SheltersSectionContainer';
import NewsletterFormContainer from 'dpl/containers/NewsletterFormContainer';
import UserHeaderLoginContainer from 'dpl/Pages/UserAuth/containers/UserLoginButtonContainer';
import PressSectionContainer from 'dpl/containers/PressSectionContainer';
import BuyerAppDownloadTakeover from 'dpl/homepage/components/BuyerAppDownloadTakeover';
import BreederProfileBreedOverviewPages from 'dpl/components/BreederProfile/Index/BreederProfileList/BreederProfileBreedOverviewPages';

initReactApp({
  HomeSearchContainer,
  HomeScreeningSectionContainer,
  HomeBreedersSectionContainer,
  HomeSheltersSectionContainer,
  /* eslint-disable-next-line react/display-name */
  UserHeaderLoginContainer: () => <UserHeaderLoginContainer showSignupText />,
  NewsletterFormContainer,
  PressSectionContainer,
  BuyerAppDownloadTakeover,
  /* eslint-disable-next-line react/display-name */
  BreederProfileBreedOverviewPages: () => (
    <BreederProfileBreedOverviewPages header="Learn more about our popular breeds" />
  )
});
