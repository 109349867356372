import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { makeSequence, isMobileUA } from 'dpl/shared/utils';
import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';

const IS_MOBILE_UA = isMobileUA();

export default function BreederProfileLocationLinksGroupSkeleton({
  wideLayout,
  limit,
  isStatePage
}) {
  return (
    <div className="BreederProfileLocationLinksGroup f2 f3-sm">
      <SkeletonBlock
        className="mb4 mb6-lg f3 f4-md lh-normal"
        height="1em"
        width="15em"
      />
      <ul className={classnames('row slim-gutters', { mb18: wideLayout })}>
        {makeSequence(limit).map(index => (
          <li
            key={index}
            className={classnames('mb2 col-12', {
              BreederProfileLocationLinksGroup__links:
                (wideLayout && !isStatePage) || (isStatePage && !IS_MOBILE_UA),
              'col-md-6': !isStatePage
            })}
          >
            <SkeletonBlock
              className="dark-gray font-18"
              height="1em"
              width="80%"
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

BreederProfileLocationLinksGroupSkeleton.propTypes = {
  linksData: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object).isRequired
  }).isRequired,
  wideLayout: PropTypes.bool,
  limit: PropTypes.number,
  isStatePage: PropTypes.bool
};

BreederProfileLocationLinksGroupSkeleton.defaultProps = {
  wideLayout: false,
  limit: 10,
  isStatePage: false
};
