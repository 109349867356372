import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withRotatingContent from 'dpl/decorators/withRotatingContent';
import ScreeningSection from 'dpl/homepage/components/ScreeningSection';

function ScreeningSectionContainer(props) {
  return <ScreeningSection {...props} />;
}

const ITEMS = [
  {
    title: 'Health',
    content: `
    Members of our community prioritize the well-being of their dogs above all else.
    Good Breeders are passionate about the health of their dogs, providing necessary
    care to give them the best chance at a long and healthy life.
  `
  },
  {
    title: 'Behavior',
    content: `
    Our partners do their best to make sure their dogs comfortably transition into your home.
    From playing with enrichment toys to proper socialization, they work hard to ensure
    each dog has a shot at a well-adjusted life.
  `
  },
  {
    title: 'Welfare',
    content: `
    Irresponsible sources lead to inhumane treatment of dogs.
    Together, we can put an end to irresponsible practices, and give all dogs the happy,
    healthy, belly rub-filled lives they deserve.
  `
  }
];

export default compose(
  connect(() => ({
    items: ITEMS,
    rotatingContentCount: ITEMS.length
  })),
  withRotatingContent
)(ScreeningSectionContainer);
