import React from 'react';

import { isMobileUA } from 'dpl/shared/utils';

import Search from '../components/Search';
import MobileSearchContainer from './MobileSearchContainer';

export default function SearchContainer() {
  return isMobileUA() ? <MobileSearchContainer /> : <Search />;
}
