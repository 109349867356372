import { useEffect, useState } from 'react';

import {
  getLocalStorageValue,
  setLocalStorageValue
} from '../utils/localStorage';

export const SNOOZABLE_COMPONENTS = 'snoozable_components';

export const FOREVER_DURATION = -1;
export const ONE_DAY_DURATION = 24 * 60 * 60 * 1000;

export function useSnoozed() {
  const initialState = getLocalStorageValue(SNOOZABLE_COMPONENTS) || {};
  const [state, setState] = useState(initialState);

  useEffect(() => {
    setLocalStorageValue(SNOOZABLE_COMPONENTS, state);
  }, [state]);

  /**
   * @param {string} key
   */
  function setSnoozed(key) {
    setState(prevState => {
      return {
        ...prevState,
        [key]: Date.now()
      };
    });
  }

  /**
   * @param {string} key
   * @param {number} duration
   * @returns {boolean}
   */
  function isSnoozed(key, duration) {
    const value = state[key];

    if (!value) {
      return false;
    }

    if (duration === FOREVER_DURATION) {
      return true;
    }

    if (duration > 0 && Date.now() - value > duration) {
      return false;
    }

    return true;
  }

  return {
    isSnoozed,
    setSnoozed
  };
}
