import { useSelector } from 'react-redux';

export function useAdUrlParams() {
  const isAdUrl = useSelector(
    ({ queryParams: { utm_medium: utmMedium } }) =>
      utmMedium === 'cpa' || utmMedium === 'cpc'
  );

  return { isAdUrl };
}
