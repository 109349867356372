import React from 'react';

import Icon from 'dpl/common/components/Icon';
import { useAdUrlParams } from 'dpl/hooks/useAdUrlParams';
import { FOREVER_DURATION, useSnoozed } from 'dpl/shared/hooks/useSnoozed';

import { DownloadHero } from './DownloadHero';

const FEATURE_KEY = 'homepage-app-download-hero';

export default function BuyerAppDownloadTakeover() {
  const { isSnoozed, setSnoozed } = useSnoozed();
  const { isAdUrl } = useAdUrlParams();

  if (isAdUrl) {
    return null;
  }

  if (isSnoozed(FEATURE_KEY, FOREVER_DURATION)) {
    return null;
  }

  function handleCloseClick() {
    setSnoozed(FEATURE_KEY, FOREVER_DURATION);
  }

  return (
    <div className="BuyerAppDownloadTakeover flex flex-column items-end">
      <button
        type="button"
        className="pt4 ph6 mid-gray"
        onClick={handleCloseClick}
      >
        <Icon name="close" />
      </button>
      <DownloadHero />
    </div>
  );
}
