import React from 'react';

import withMobileMenu from 'dpl/decorators/withMobileMenu';
import MobileSearch from 'dpl/homepage/components/MobileSearch';

function MobileSearchContainer(props) {
  return <MobileSearch {...props} />;
}

export default withMobileMenu(MobileSearchContainer);
