import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function ScreeningSection({
  items,
  setRef,
  shownRotatingContentIdx,
  isRotatingContentPaused,
  rotatingContentInterval,
  resumeRotatingContent,
  pauseRotatingContent
}) {
  return (
    <div className="HomeScreeningSection" ref={setRef}>
      <div className="overflow-hidden">
        <ul className="flex white mb3 justify-between bb b--white pr8 pr16-md">
          {items.map(({ title }, idx) => {
            const isSelected = shownRotatingContentIdx === idx;
            const shouldTransition = isSelected && !isRotatingContentPaused;

            const transition = [
              isSelected && 'opacity .3s ease',
              shouldTransition &&
                `transform ${rotatingContentInterval}ms linear`
            ]
              .filter(Boolean)
              .join(', ');

            return (
              <li key={idx}>
                <button
                  type="button"
                  className="pb3 bw2 nowrap fw-medium f4"
                  onMouseEnter={() => pauseRotatingContent(idx)}
                  onMouseLeave={resumeRotatingContent}
                >
                  {title}
                </button>
                <span
                  style={{ transition }}
                  className={classnames(
                    'HomeScreeningSection__border db pb1 bg-red',
                    {
                      'HomeScreeningSection__border--transitioning':
                        shouldTransition,
                      'o-0': !isSelected
                    }
                  )}
                />
              </li>
            );
          })}
        </ul>
        <div className="relative overflow-hidden">
          {items.map(({ content }, idx) => {
            const isShown =
              shownRotatingContentIdx === idx ||
              (shownRotatingContentIdx === null && idx === 0);
            return (
              <p
                key={idx}
                className={classnames(
                  'HomeScreeningSection__content transition',
                  {
                    'o-0 absolute': !isShown,
                    'HomeScreeningSection__content--shown':
                      shownRotatingContentIdx === idx
                  }
                )}
              >
                {content}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
}

ScreeningSection.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired
    })
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  setRef: PropTypes.object.isRequired,
  shownRotatingContentIdx: PropTypes.number,
  isRotatingContentPaused: PropTypes.bool.isRequired,
  rotatingContentInterval: PropTypes.number.isRequired,
  resumeRotatingContent: PropTypes.func.isRequired,
  pauseRotatingContent: PropTypes.func.isRequired
};

ScreeningSection.defaultProps = {
  shownRotatingContentIdx: null
};
