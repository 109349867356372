import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { isMobileUA } from 'dpl/shared/utils';
import Icon from 'dpl/common/components/Icon';
import SmartImage from 'dpl/components/SmartImage';

const logoWidth = isMobileUA() ? '100px' : '140px';

export default function QuoteCarousel({
  className,
  isOnFirstRotatingContentStep,
  isOnLastRotatingContentStep,
  nextRotatingContent,
  pauseRotatingContent,
  prevRotatingContent,
  quotes,
  resumeRotatingContent,
  setRef,
  shownRotatingContentIdx,
  title,
  titleClassName
}) {
  return (
    <div
      className={classnames(
        'QuoteCarousel pt14 pb12 overflow-hidden',
        className
      )}
      ref={setRef}
      onMouseEnter={pauseRotatingContent}
      onMouseLeave={resumeRotatingContent}
    >
      <div className="container">
        <div className="row slim-gutters-mobile">
          <div className="col-lg-10 col-xl-9 center tc">
            <h3 className={classnames('mb4', titleClassName)}>{title}</h3>
            <ul className="mb6 mb6-lg flex items-center">
              {quotes.map((quote, idx) => (
                <li
                  className={classnames('QuoteCarousel__quote transition', {
                    'o-0': idx !== shownRotatingContentIdx
                  })}
                  style={{
                    transform: `translateX(${shownRotatingContentIdx * -100}%)`
                  }}
                  key={idx}
                >
                  <p className="QuoteCarousel__quoteSymbol">&ldquo;</p>
                  <p className="mb4 mb10-lg fw-medium-l f5-lg">{quote.text}</p>
                  {quote.logo_url && (
                    <SmartImage src={quote.logo_url} width={logoWidth} />
                  )}
                  {quote.user && (
                    <div className="fw-medium">
                      <p className="mb1 f5-lg">- {quote.user.title}</p>
                      <p className="mb3">{quote.user.subtitle}</p>
                      <SmartImage
                        className="br-100"
                        src={quote.user.profile_photo_url}
                        height="64px"
                        width="64px"
                      />
                    </div>
                  )}
                </li>
              ))}
            </ul>
            <div className="inline-flex align-items-center">
              <button
                className={classnames({ 'o-30': isOnFirstRotatingContentStep })}
                disabled={isOnFirstRotatingContentStep}
                onClick={prevRotatingContent}
                type="button"
              >
                <Icon name="fat-arrow-left" />
              </button>
              <span className="ph3 f2">
                {shownRotatingContentIdx + 1} / {quotes.length}
              </span>
              <button
                className={classnames({ 'o-30': isOnLastRotatingContentStep })}
                disabled={isOnLastRotatingContentStep}
                onClick={nextRotatingContent}
                type="button"
              >
                <Icon name="fat-arrow-right" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

QuoteCarousel.propTypes = {
  className: PropTypes.string,
  isOnFirstRotatingContentStep: PropTypes.bool.isRequired,
  isOnLastRotatingContentStep: PropTypes.bool.isRequired,
  nextRotatingContent: PropTypes.func.isRequired,
  pauseRotatingContent: PropTypes.func.isRequired,
  prevRotatingContent: PropTypes.func.isRequired,
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      logo_url: PropTypes.string,
      text: PropTypes.string.isRequired,
      user: PropTypes.shape({
        name: PropTypes.string,
        program_name: PropTypes.string,
        photo_url: PropTypes.string
      })
    })
  ).isRequired,
  resumeRotatingContent: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  setRef: PropTypes.object.isRequired,
  shownRotatingContentIdx: PropTypes.number,
  title: PropTypes.string,
  titleClassName: PropTypes.string
};

QuoteCarousel.defaultProps = {
  className: 'bg-lightest-blue',
  shownRotatingContentIdx: null,
  title: 'Good Dog in the press',
  titleClassName: null
};
