import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { isMobileUA } from 'dpl/shared/utils';
import SmartImage from 'dpl/components/SmartImage';
import BreederProfileHealthTestCategories from 'dpl/components/BreederProfileHealthTestCategories';
import Icon from 'dpl/common/components/Icon';

const IMAGE_WIDTH = isMobileUA() ? '340px' : '558px';

function BreedersSectionArrow({ direction, onClick, isShown }) {
  return (
    <button
      type="button"
      disabled={!isShown}
      className={classnames('HomeBreedersSectionArrow', {
        silver: !isShown
      })}
      onClick={onClick}
      aria-label={`${direction === 'left' ? 'Previous' : 'Next'} Breeder`}
    >
      <Icon name={`fat-arrow-${direction === 'left' ? 'left' : 'right'}`} />
    </button>
  );
}

BreedersSectionArrow.propTypes = {
  direction: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isShown: PropTypes.bool.isRequired
};

export default function BreedersSection({
  nextRotatingContent,
  prevRotatingContent,
  featuredBreeders,
  pauseRotatingContent,
  resumeRotatingContent,
  setRef,
  shownRotatingContentIdx
}) {
  return (
    <div
      className="HomeBreedersSection"
      ref={setRef}
      onMouseEnter={pauseRotatingContent}
      onMouseLeave={resumeRotatingContent}
    >
      <div className="container">
        <h3 className="mb14-sm mb8 tc">Meet a few of our breeders</h3>
      </div>
      <div className="HomeBreedersSection__container center flex items-center">
        <div className="container flex-auto">
          <div className="row flex-wrap-reverse">
            <div className="col-xl-5 col-lg-6 pt4 mb3">
              <div className="relative h-100">
                {featuredBreeders.map(
                  (
                    { title, firstName, content, healthTests, profileUrl },
                    idx
                  ) => (
                    <div
                      key={idx}
                      className={classnames('transition', {
                        'z-0 pointer-events-none o-0 absolute absolute--fill':
                          idx !== shownRotatingContentIdx,
                        'HomeBreedersSection__content--shown z-1':
                          idx === shownRotatingContentIdx
                      })}
                    >
                      <h5 className="mb6-sm mb3">{title}</h5>
                      <div className="mb8-sm mb4">
                        <p>{content}</p>
                      </div>
                      <h6 className="mb4-sm mb2">
                        Health tests I do on my dogs:
                      </h6>
                      <div className="row">
                        <div className="col-xl-10 col-sm-9">
                          <div className="mb6">
                            <BreederProfileHealthTestCategories
                              healthTests={healthTests}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-6 col-lg-8">
                          <a
                            href={profileUrl}
                            className="button button--large button--primary"
                          >
                            View&nbsp;
                            <span className="d-none d-lg-inline-block">my</span>
                            <span className="d-lg-none">
                              {firstName}&apos;s
                            </span>
                            &nbsp;profile
                          </a>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="col-lg-6 offset-xl-1 mb3 tc">
              <div className="f0 relative">
                {featuredBreeders.map(({ pictureUrl, profileUrl }, idx) => (
                  <a
                    href={profileUrl}
                    key={idx}
                    style={{
                      transform: `translateX(${
                        (shownRotatingContentIdx - idx) * -100
                      }px)`
                    }}
                    className={classnames(
                      'HomeBreedersSection__breederImage db transition',
                      {
                        'o-0 absolute absolute--fill pointer-events-none':
                          idx !== shownRotatingContentIdx
                      }
                    )}
                  >
                    <SmartImage
                      Tag="div"
                      width={IMAGE_WIDTH}
                      height={IMAGE_WIDTH}
                      crop
                      lazy={idx === 0}
                      src={pictureUrl}
                      alt="Featured breeder"
                      className="mw-100 cover bg-center br1"
                    />
                  </a>
                ))}
              </div>
              <div className="tr-l mt4">
                <div className="inline-flex items-center">
                  <BreedersSectionArrow
                    direction="left"
                    onClick={prevRotatingContent}
                    isShown={shownRotatingContentIdx !== 0}
                  />
                  <span className="ph3 silver f2">
                    {shownRotatingContentIdx + 1} / {featuredBreeders.length}
                  </span>
                  <BreedersSectionArrow
                    direction="right"
                    onClick={nextRotatingContent}
                    isShown={
                      shownRotatingContentIdx !== featuredBreeders.length - 1
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

BreedersSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  setRef: PropTypes.object.isRequired,
  nextRotatingContent: PropTypes.func.isRequired,
  prevRotatingContent: PropTypes.func.isRequired,
  shownRotatingContentIdx: PropTypes.number.isRequired,
  resumeRotatingContent: PropTypes.func.isRequired,
  pauseRotatingContent: PropTypes.func.isRequired,
  featuredBreeders: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      profileUrl: PropTypes.string.isRequired,
      pictureUrl: PropTypes.string.isRequired
    })
  ).isRequired
};
